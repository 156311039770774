import React from 'react'
import {} from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Prefooter from '../components/prefooter'
import PageHeader from '../components/page-header'
import Block from '../components/waypoint-block'
import Drift from '../components/waypoint-drift'
import PageIntro from '../components/page-intro'
import ArrowForward from '../components/Icons/ArrowForward'
import DownArrow from '../components/Icons/ArrowDown'

function PatrickPage({ data }) {
  return (
    <React.Fragment>
      <Helmet
        title="Versett – A leading consulting firm specialized in digital transformation, working globally, with offices in Canada and Europe."
        meta={[
          {
            name: 'description',
            content:
              'Versett partners with high-growth companies to design, build, and grow their products. We build competitive advantages through strategy, design, and technology.',
          },
          { name: 'keywords', content: 'versett, thesis, readme, about' },
          { property: 'og:title', content: 'Versett' },
          {
            property: 'og:description',
            content:
              'Versett partners with high-growth companies to design, build, and grow their products. We build competitive advantages through strategy, design, and technology.',
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-thesis.jpg',
          },
          { property: 'og:url', content: 'https://versett.com/thesis/' },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Thesis' },
        ]}
      >
        <html lang="en" className="s-page" />
      </Helmet>
      <Layout>
        <section className="s-container s-page__hero s-page__hero--short">
          <PageHeader
            lede="Raw Materials"
            subheading="Welcome Invest Like the Best listeners!"
          />
          <DownArrow />
        </section>
        <Drift />
        <PageIntro>
          <Block>
            <h4 className="optimal-width optimal-width--center">
              Raw Materials is our internal knowledge repository of the best of
              what we’ve read across the internet, academia, books and
              everywhere else.
            </h4>
            <p>
              As one of our clients put it, "I used to read Twitter, now I just
              check this." Read through hundreds of articles, resources and
              writing from all corners of the world. We are making it available
              to listeners of the show, we hope you find something interesting
              in it. Click the link below to get access to our{' '}
              <a
                href="https://www.notion.so/1514551d39c844a49e2503cce5bc61b5?v=a709f6ef26184547ae24dd3d045ece3f"
                target="_blank"
                rel="noopener noreferrer"
              >
                Raw Materials
              </a>{' '}
              library.
            </p>

            <div className="materials-link">
              <a
                href="https://www.notion.so/1514551d39c844a49e2503cce5bc61b5?v=a709f6ef26184547ae24dd3d045ece3f"
                target="_blank"
                rel="noopener noreferrer"
              >
                Access the repository
              </a>
              <ArrowForward />
            </div>
          </Block>
          <p class="color-secondary">
            PS, interested in how Versett can help you move faster? Learn more
            about how we work by reading our <a href="/thesis">thesis</a>.
          </p>
        </PageIntro>
        <section className="s-container s-section"></section>
        <Prefooter />
      </Layout>
    </React.Fragment>
  )
}

export default PatrickPage
